import { DisplayCurrencyEnum } from "@/Api/generated";

export function getCurrencySymbol(currency?: DisplayCurrencyEnum) {
  if (currency === "USD" || currency === "CAD" || currency === "AUD") return "$";
  if (currency === "EUR") return "€";
  if (currency === "GBP") return "£";
  if (currency === "JPY") return "¥";
  if (currency === "CNY") return "¥";

  return "$";
}
