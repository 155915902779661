import { useState } from "react";

import { PropertyRegulatoryOrdinance, RetrofitProject } from "@/Api/generated";
import { RetrofitCardModalState } from "@/pages/DrilldownPage/ProjectsTab/RetrofitProjectManagerBoardCard/RetrofitCardMoveModal";
import { RankingTypeKey } from "@/pages/ProjectsPage/HeatMapCard";

export function useProjectsState() {
  const [selectedRankingTypeKey, setSelectedRankingTypeKey] =
    useState<RankingTypeKey>("total_fine_amount");

  const [selectedRetrofitProject, setSelectedRetrofitProject] =
    useState<RetrofitProject>(undefined);

  const [retrofitCardMoveModalState, setRetrofitCardMoveModalState] =
    useState<RetrofitCardModalState>();

  const [moveBackDestination, setMoveBackDestination] = useState<string>(undefined);

  const [selectedRegulatoryOrdinance, setSelectedRegulatoryOrdinance] =
    useState<PropertyRegulatoryOrdinance>(undefined);

  const [showSelectedRegulatoryOrdinanceDrawer, setShowSelectedRegulatoryOrdinanceDrawer] =
    useState(false);
  const [isRetrofitCardMoveModalShowing, setIsRetrofitCardMoveModalShowing] = useState(false);

  const [isRetrofitCloseWithoutSavingModalShowing, setIsRetrofitCloseWithoutSavingModalShowing] =
    useState(false);
  const [showRetrofitManagerDrawer, setShowRetrofitManagerDrawer] = useState(false);

  return {
    selectedRegulatoryOrdinance,
    setSelectedRegulatoryOrdinance,
    moveBackDestination,
    setMoveBackDestination,
    retrofitCardMoveModalState,
    setRetrofitCardMoveModalState,
    selectedRankingTypeKey,
    setSelectedRankingTypeKey,
    selectedRetrofitProject,
    setSelectedRetrofitProject,

    showSelectedRegulatoryOrdinanceDrawer,
    setShowSelectedRegulatoryOrdinanceDrawer,
    isRetrofitCardMoveModalShowing,
    isRetrofitCloseWithoutSavingModalShowing,
    setIsRetrofitCloseWithoutSavingModalShowing,
    setIsRetrofitCardMoveModalShowing,
    showRetrofitManagerDrawer,
    setShowRetrofitManagerDrawer,
  };
}
