import type { Unit } from "@/js/types/utils";

export function unitFormatter(unit: Unit): string {
  if (!unit) {
    return "";
  }

  switch (unit) {
    case "kwh":
      return "kWh";
    case "kbtu":
      return "kBtu";
    case "cubic_meters":
      return "m³";
    default:
      return unit.replaceAll("_", " ");
  }
}

// for use in convertToCsvAndDownload, m³ doesn't display correctly in excel so we convert it to m3
export function processUnitValue(unit: string): string {
  switch (unit) {
    case "m³":
      return "m3";
    default:
      return unit;
  }
}
