import type { IconsType } from "@/components/SvgIcon";
import type {
  ProjectTagType,
  PropertyTagType,
  StatusTagType,
  SystemTagType,
  TagType,
} from "./types";

import SvgIcon from "@/components/SvgIcon";

import { classnames } from "@/js/utils/cambio";

import { TagDescriptions } from "./constants";

interface TagProps {
  icon?: IconsType;
  text?: string;
  color?: string;
  type?: TagType;
  strong?: boolean;
}

/**
 * Parent component to any and all tags. We have many tag presets, but all facets to each preset
 * are override-able. We can also use this generic <Tag /> component with any of the preset types,
 * or we can also use the specialty tag components, which will throw a type error if we try to use
 * a type that doesn't belong to them. For example:
 *
 * <Tag type="extreme" /> // works!
 * <Tag type="lighting" /> // works!
 *
 * <StatusTag type="extreme" /> // works!
 * <StatusTag type="lighting" /> // x does not work!
 *
 * <SystemTag type="extreme" /> // x does not work!
 * <SystemTag type="lighting" /> // works!
 *
 * this also works - everything is override-able
 * <SystemTag type="lighting" color="pink" icon="building" text="anything but lighting" />
 */
export default function Tag({ type = "generic", ...props }: TagProps) {
  const { icon, color, text, strong } = {
    ...TagDescriptions[type],
    ...props,
  };

  return (
    <span className={classnames("Tag", color, { strong, [type as string]: type !== "generic" })}>
      {icon ?
        <SvgIcon name={icon} />
      : null}
      {text}
    </span>
  );
}

export const StatusTag = (props: TagProps & { type: StatusTagType | null }) =>
  props.type === null ? <span>--</span> : <Tag {...props} />;
export const SystemTag = (props: TagProps & { type: SystemTagType }) => <Tag {...props} />;
export const ProjectTag = (props: TagProps & { type: ProjectTagType }) => <Tag {...props} />;
export const PropertyTag = (props: TagProps & { type: PropertyTagType }) => <Tag {...props} />;
