import type { ReactNode } from "react";

import { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { ContentType } from "recharts/types/component/Tooltip";
import { AxisDomain } from "recharts/types/util/types";

import ChartTooltip from "@/components/Charts/ChartTooltip";

import { ChartColors } from "@/js/constants/cambio";
import { ChartDataField } from "@/js/types/charts";

import { DisplayCurrencyEnum } from "@/Api/generated";

import { calculateTrendLineData } from "./calculateTrendLineData";
import { getNestedValue } from "./cambio";
import { getCurrencySymbol } from "./currency";

export function currencyTickFormatter(tick: number, currency: DisplayCurrencyEnum) {
  return `${getCurrencySymbol(currency)}${tick.toLocaleString("en-US")}`;
}

type TickCalculationParams = {
  data: Record<string, any>[];
  valueTransformer: (value: number) => number;
  yAxisChartDataFields: ChartDataField[];
  tickFormatter: (value: number) => string;
  // A list of list of ChartDataField keys whose values should be summed when looking for the max y-axis value at a given x-axis value.
  // For example if you had a barchart where each bar was made up of 3 summed values, you would pass in [["key1", "key2", "key3"]].
  // If these values were all 5 at a given x-axis value, the max y-axis value would be 15, if there was no grouping provided then the max would be calculated as 5.
  keyGroups?: string[][];
};

type TickCalculationResult = {
  ticks: number[];
  longestTickLabelWidth: number;
};

/**
 * Calculates the values for the y-axis ticks such that there are exactly 5 ticks (including 0) with nice values.
 * It also calculates the width of the longest tick label so that charts y-axises can be aligned with the left side of their parent card .
 *
 * */
export function calculateTicks({
  data,
  valueTransformer,
  yAxisChartDataFields,
  tickFormatter,
  keyGroups = [],
}: TickCalculationParams): TickCalculationResult {
  const values = data.flatMap((item) => {
    const dataPointValues = yAxisChartDataFields.map(
      (field) => valueTransformer(getNestedValue(item, field.key)) || 0,
    );

    const groupedValues = keyGroups.map((group) => {
      const sum = group.reduce((acc, key) => {
        const index = yAxisChartDataFields.findIndex((field) => field.key === key);

        return acc + (dataPointValues[index] || 0);
      }, 0);

      return sum;
    });

    return [...dataPointValues, ...groupedValues];
  });
  let maxDataValue = Math.max(...values);
  const minDataValue = 0; // Ensure the scale starts at 0

  maxDataValue = maxDataValue > 0 ? maxDataValue : 1; // Ensure some range if all data is 0

  const range = maxDataValue - minDataValue;
  const idealStep = range / 4;
  const magnitude = Math.pow(10, Math.floor(Math.log10(idealStep)));
  const niceStep = Math.ceil(idealStep / magnitude) * magnitude;

  maxDataValue = niceStep * 4 + minDataValue; // Adjust the max value to fit the nice steps

  const generatedTicks = [0, 1, 2, 3, 4].map((i) => minDataValue + niceStep * i);

  const longestTickLabelWidth = generatedTicks.reduce((max, tick) => {
    const formattedTick = tickFormatter(tick);

    return Math.max(max, getTextWidth(formattedTick));
  }, 0);

  return { ticks: generatedTicks, longestTickLabelWidth };
}

// Get the width of a yAxisLabel. This is used to set the width of y-axis so that the farthest left text is at the border of the charts parent card.
function getTextWidth(text: string) {
  const body = document.body;
  const parentDiv = document.createElement("div");

  parentDiv.className = "Chart";

  const element = document.createElement("span");

  element.textContent = text;
  element.className = "chart-yaxis-tick";
  element.style.visibility = "hidden";

  parentDiv.appendChild(element);
  body.appendChild(parentDiv);

  const width = element.offsetWidth;

  body.removeChild(parentDiv);

  return width;
}

// gets shared style props for the recharts x-axis as used in custom chart components
export function getRechartsXAxisStyleProps(
  xAxisKey: string,
  tickFormatter: (value: any) => string,
) {
  return {
    dataKey: (dataObject: Record<string, any>) => {
      return getNestedValue(dataObject, xAxisKey);
    },
    minTickGap: 22,
    padding: { left: 16, right: 16 },
    tickMargin: 4,
    tickFormatter: tickFormatter,
    className: "chart-xaxis-tick",
    tickLine: {
      stroke: getComputedStyle(document.documentElement).getPropertyValue("--gray-3"),
    },
    tick: { fill: getComputedStyle(document.documentElement).getPropertyValue("--gray-5") },
    strokeWidth: 1,
    axisLine: false,
  };
}

// gets shared style props for the recharts y-axis as used in custom chart components
export function getRechartsYAxisStyleProps(
  data: Record<string, any>[],
  valueTransformer: (value: number) => number,
  yAxisChartDataFields: ChartDataField[] | undefined,
  tickFormatter: (value: number) => string,
  keyGroups: string[][] = [],
) {
  const { ticks, longestTickLabelWidth } = calculateTicks({
    data,
    valueTransformer,
    yAxisChartDataFields,
    tickFormatter,
    keyGroups: keyGroups,
  });

  return {
    className: "chart-yaxis-tick",
    axisLine: false,
    tickLine: false,
    tickMargin: 6,
    domain: [0, "dataMax"] as AxisDomain,
    ticks: ticks,
    tickFormatter: tickFormatter,
    width: longestTickLabelWidth + 12,
  };
}

// gets shared style props for the recharts cartesian grid as used in custom chart components
export function getCartesianGridProps() {
  return {
    stroke: getComputedStyle(document.documentElement).getPropertyValue("--gray-2"),
    vertical: false,
    strokeWidth: 1,
  };
}

export function getRechartsTooltipProps(
  data: any[],
  xAxisKey: string,
  yAxisChartDataFields: ChartDataField[] | undefined,
  tooltipLabelKey: string,
  tooltipLabelFormatter: (value: any) => string,
  tooltipValueFormatter: (value: number) => string,
) {
  return {
    cursor: { fill: getComputedStyle(document.documentElement).getPropertyValue("--gray-2") },
    isAnimationActive: false,
    content: (({ payload, ...rest }: { payload: Payload<ValueType, NameType>[] }) => {
      // payload can sometimes be passed as null from Recharts
      if (!payload) {
        return;
      }

      return (
        <ChartTooltip
          chartDataFields={yAxisChartDataFields}
          data={data.find((item) => item[xAxisKey] === payload[0]?.payload[xAxisKey])}
          labelKey={tooltipLabelKey}
          labelFormatter={tooltipLabelFormatter}
          valueFormatter={tooltipValueFormatter}
        />
      );
    }) as ContentType<ValueType, NameType>,
  };
}

/** gets shared style props for the recharts pie chart as used in custom chart components;
 * components other than our PieChart component may make use of the recharts pie chart component like the  Retrofit Opportunity Snapshot chart
 */
export function getRechartsPieTooltipProps<T>(
  data: T[],
  nameKey: keyof T,
  dataKey: string,
  getColor: (dataPoint: T) => string,
  tooltipLabelKey: string,
  tooltipLabelFormatter: (value: any) => string,
  tooltipValueFormatter: (value: number, object: any) => ReactNode,
  nameFormatter: (name: string) => string,
) {
  return {
    isAnimationActive: false,
    content: (({ payload }: { payload: Payload<ValueType, NameType>[] }) => {
      const datapoint = data.find((item) => item[nameKey] === payload[0]?.name);

      return (
        <ChartTooltip
          chartDataFields={[
            {
              key: dataKey,
              name: null,
              color: getColor(datapoint),
            },
          ]}
          data={datapoint}
          labelKey={tooltipLabelKey}
          labelFormatter={tooltipLabelFormatter}
          valueFormatter={tooltipValueFormatter}
          nameFormatter={nameFormatter}
        />
      );
    }) as ContentType<ValueType, NameType>,
  };
}

export function getReferenceLineConfig(
  chartData: Record<string, any>[],
  xDataKey: string,
  yDataKey: string,
  yAxisId?: string | undefined,
) {
  return {
    segment: calculateTrendLineData(chartData, xDataKey, yDataKey),
    stroke: ChartColors.TEAL_DARK,
    strokeDasharray: "4 4",
    strokeWidth: 2,
    ifOverflow: "hidden" as const,
    yAxisId: yAxisId,
  };
}
